$(function() {

    // Setup html5 version
	var total_upload_files = 0;
	
	var uploader = bindPluploadQueue();
	var free_space = 0;
	var free_space_label = "";
	
	function generateNewCode() {
		$("#code-field").val( $.md5(Date.now()) + $.md5(Date.now()) );
	}
	
	function modal_alert(message){
		
		var mex = $('<p id="dynamic">');
		mex.html(message);
		$("#myModal .modal-body").html(mex);
    	$("#myModal").modal('show');
		
	}
	
	function bindPluploadQueue(){
		
		generateNewCode();
		
		uploader = $("#html5_uploader").pluploadQueue({
	        // General settings
	        runtimes : 'html5',
	        url : "/upload",
	        chunk_size : '2048kb',
	        unique_names : false,

	        headers: {
	            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	        }, 
	        
	        filters: {
	            max_file_size : '4gb',
	            prevent_duplicates: true,
	            //mime_types: [
	            //    { title : "Image files", extensions : "jpg,gif,png" },
	            //    { title : "Zip files", extensions : "zip" }
	            //]
	        },
	        
	        init: {
	        	Error: function(up, error){
	        		up.stop();
	        		$(window).unbind('beforeunload');
	        		alert(Lang.get("common.upload_files_error"));
	        		$("#create_box_button").hide();
	        		$("#reset_box_button").show();
	        	},
	        	UploadComplete: function(up, files) {
	                // Called when all files are either uploaded or failed
	        		var box_form = $("#create_box_form");
	        		$(window).unbind('beforeunload');
	        		cleanError();
	        		
	        		$.ajax({
	        		      type: 'post',
	        		      url: box_form.attr("action"),
	        		      data: box_form.serialize(),
	        		      dataType: 'json',
	        		      success: function(data){
	        		    	  modal_alert(data.message);
	        		    	  resetUploader();
	        		      },
	        		      error: function(data){
	        		    	  var error = jQuery.parseJSON(data.responseText)["message"];
	        		    	  var errorsHtml = '<div class="alert alert-danger"><ul>';

	        		    	  $.each( error, function( key, value ) {
	        		              errorsHtml += '<li>' + value[0] + '</li>';
	        		              if (key == "destinatari"){
	        		            	  fg = $("#destinatari").parents("div.form-group");
	        		              } else {
	        		            	  fg = $("#"+key+"-field").parents("div.form-group");
	        		              }
	        		              fg.addClass("has-error");
	        		              fg.append('<span class="help-block">'+ value[0] +'</span>');
	        		          });
	        		          errorsHtml += '</ul></di>';
	        		    	  
	        		          $('#form-errors').html( errorsHtml );
	        		          $('#create_box_button').prop("disabled", false);
	        		      }
	        		});
	        		
	        		
	            },
	        },
	        
	        multipart_params : {
	            "code" : $("#code-field").val()
	        }
	 
	        // Resize images on clientside if we can
	        // resize : { width : 320, height : 240, quality : 90 }
	    });
		
		return uploader;
	}
	
	function isValidEmailAddress(emailAddress) {
	    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
	    return pattern.test(emailAddress);
	};
		
	function getSpaceAvailable(make_upload_call){
		$.ajax({
		      type: 'get',
		      url: $("#company_info").val(),
		      dataType: 'json',
		      success: function(data){
		    	  free_space = data.free_space;
		    	  free_space_label = data.free_space_label;
		      },
		      error: function(data){
		    	  free_space = 0;
		    	  free_space_label = "";
		      },
		      complete: function(){
		    	  $("#available_space_label").html(free_space_label);
		    	  if (make_upload_call) {
		    		  
		    		  var valid_email = true;
		    		  var emails = $("#emails-field").val();

		    		  if (emails.length > 0){
		    			  var email_list = JSON.parse(emails);

		    			  if (email_list.length > 0){
		    				  $.each(email_list, function( index, value ) {
		    					  valid_email = valid_email && isValidEmailAddress(value);
		    				  });
		    			  } else {
		    				  valid_email = false;
		    			  }
		    		  } else {
		    			  valid_email = false;
		    		  }

		    		  var filelist_length = $("#html5_uploader_filelist .plupload_file_name").length;

		    		  var total_size = 0;
		    		  var pq = $('#html5_uploader').pluploadQueue();
		    		  var files = pq.files;

		    		  $.each(files, function(i, v){
		    			  total_size += v.size;
		    		  });

		    		  if ( (valid_email == true) && (filelist_length > 0) && ( free_space >= total_size )) {

		    			  prefix = $("#code-field").val();
		    				
		    			  for (var i = 0; i < pq.files.length; i++) {
		    				  pq.files[i].name = prefix + "_" + pq.files[i].name;
		    			  }

		    			  pq.start();
		    			  
		    			  $(window).bind('beforeunload', function() {
		    				  return Lang.get("common.window_close_alert");
		    			  });
		    				
		    		  } else {

		    			  var errorsHtml = '<div class="alert alert-danger"><ul>';
		    			  
		    			  if ( valid_email == false ){
		    				  errorsHtml += '<li>'+Lang.get("common.must_be_a_valid_mail")+'</li>';
		    				  fg = $("#emails-field").parents("div.form-group");
		    				  fg.addClass("has-error");
		    				  fg.append('<span class="help-block">'+Lang.get("common.must_be_a_valid_mail")+'</span>');
		    			  }
		    			  
		    			  if ( filelist_length == 0 ){
		    				  errorsHtml += '<li>'+ Lang.get("common.must_upload_at_least_one_file") +'</li>';
		    			  }
		    				
		    			  if ( free_space < total_size ){
		    				  errorsHtml += '<li>'+ Lang.get("common.not_enough_free_space") + '</li>';
		    			  }

		    			  errorsHtml += '</ul></di>';
		    			  $( '#form-errors' ).html( errorsHtml );
		    			  $('#create_box_button').prop("disabled", false);
		    			  
		    		  }
		    	  }
		      }
		});
	}
	
	function cleanError(){
		$('#form-errors').html("");
		
		$(".has-error").each(function(e){
			$(this).removeClass("has-error");
		});
		
		$("span.help-block").each(function(e){
			$(this).detach();
		});
	}
	
	function resetUploader(){
		$("#reset_box_button").hide();
		$("#create_box_button").show();
		$("#create_box_button").prop("disabled", false);
		cleanError();
		var pq = $('#html5_uploader').pluploadQueue();
		pq.destroy();
		bindPluploadQueue();
		getSpaceAvailable(false);
		
		$("#message-field").val("");
		$("#emails-field").val("");
		$(".multiple_emails-container").remove();
		
		$("#emails-field").multiple_emails({
			position: 'top',
			theme: 'bootstrap',
			checkDupEmail: true
		});
	}
	
	$("#reset_box_button").on("click", function(e){
		e.preventDefault();
		e.stopPropagation();
		
		resetUploader();
	});
	
	$('#create_box_button').on("click", function(e) {
		e.preventDefault();
		e.stopPropagation();
		$(this).prop("disabled",true);
		
		cleanError();
		
		getSpaceAvailable(true);
		
    });
	
	$("#emails-field").multiple_emails({
		position: 'top',
		theme: 'bootstrap',
		checkDupEmail: true
	});
	
	$('.carousel').carousel();
	
	var $item = $('.carousel .item');
	var $wHeight = $(window).height();

	$item.height($wHeight); 
	$item.addClass('full-screen');

	$('.carousel img').each(function() {
	  var $src = $(this).attr('src');
	  var $color = $(this).attr('data-color');
	  $(this).parent().css({
	    'background-image' : 'url(' + $src + ')',
	    'background-color' : $color
	  });
	  
	  $(this).remove();
	});

	$(window).on('resize', function (){
	  $wHeight = $(window).height();
	  $item.height($wHeight);
	});
	
	$item.eq(0).addClass('active');
    
    $(".carica-files").on("click", function (e) {
        e.preventDefault();
        $(".container-uploader").toggleClass("open");
    })
    
    $(".s3_button_download").on("click", function (e) {
    	e.preventDefault();
    	e.stopPropagation();
    	var _s3_button_download = $(this);
    	
    	if (!$(this).hasClass("admin")){
    		$("#download_message").show();
    		_s3_button_download.hide();
    	}
    	
    	$.ajax({
    		type: 'get',
    		async: false,
    		url: $("#receiver_downloaded_url").val(),
    		dataType: 'html',
    		success: function(data){ },
		    error: function(data){ }
		});
    	
    	var s3_url = $(this).parent().find(".s3-url").val();
    	
    	if (typeof s3_url === "undefined") {
    		return;
    	} else {
    		document.location = $(this).parent().find(".s3-url").val();
    	}
    });
        
});