$(function() {
	var bindZoom = function() {

		$(".zoomable").off("click");
		$(".zoomable").on("click", function(e){
			
			e.stopPropagation();
			e.preventDefault();
			
			var link = $(this).attr("data-zoom_url");
			
			var img = $('<img id="dynamic">');
			img.attr('src', link);
			$("#myModal .modal-body").html(img);
	    	$("#myModal").modal('show');
			
		});
	}
	
	bindZoom();
});