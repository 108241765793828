$(function() {
	
	$('tbody.sortble').sortable({
		axis: 'y',
		update: function( event, ui ) {
			
			var url = $(this).attr("data-update-url");
			
			var data = $(this).sortable('serialize');
			
			data = data + "&_token=" + $("#csrf_token").val();

	        // POST to server using $.post or $.ajax
	        $.ajax({
	        	
	            data: data,
	            type: 'POST',
	            url: url,
	            
	        });
		}
	});
	
	
});