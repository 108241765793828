$(function() {
	$(".is_link").on("click", function(e){
    	e.preventDefault();
    	e.stopPropagation();
    	
    	var link = $(this).attr("data-link").toLowerCase();
    	if (!link.match("^https://")) {
    		if (!link.match("^http://")) {
    			link = "http://" + link;
    		}
    	}
    	
    	//if ($(this).hasClass("admin") ){
    		window.open(link);
    	//} else {
    	//	window.location.href = link;
    	//}
    });
});